module.exports = new Promise(async (resolve) => {

  function getRandomNumber(min, max) {
    if (min > max) {
        throw new Error("Minimum value must be less than or equal to the maximum value.");
    }
    // Generate and return a random integer between min and max, inclusive
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const urls = ["https://positive-intentions.github.io/cryptography/remoteEntry.js","https://cryptography.positive-intentions.com/remoteEntry.js"]


  // function checkUrl(url) {
  //   return fetch(url, {
  //     method: "HEAD",
  //     mode: 'no-cors'
  //   })
  //     .then(res => {
  //       if (res.ok) {
  //         return url; // Return the URL if the resource is available
  //       }
  //       throw new Error(`Resource not available at ${url}`); // Throw if resource is not available
  //     });
  // }

  // const availabilityPromises = urls.map(url => checkUrl(url));

  // // Use Promise.race to find the first URL that responds with an available resource
  // const firstAvailableUrl = await Promise.race(availabilityPromises)
  //   .catch(error => {
  //     // Handle the case where none of the URLs are available
  //     reject(new Error('None of the URLs responded positively: ' + error.message));
  //   });

  const remoteUrlWithVersion = urls[getRandomNumber(0, urls.length - 1)]
  const script = document.createElement('script')
  script.src = remoteUrlWithVersion
  script.onload = () => {
    // the injected script has loaded and is available on window
    // we can now resolve this Promise
    const proxy = {
      get: (request) => window.cryptography.get(request),
      init: (arg) => {
        try {
          return window.cryptography.init(arg)
        } catch(e) {
          console.log('remote container already initialized')
        }
      }
    }
    resolve(proxy)
  }
  // inject this script with the src set to the versioned remoteEntry.js
  document.head.appendChild(script);
})
;